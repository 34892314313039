import React, { PropsWithChildren } from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

function FixedFormFooter({ css, children }: PropsWithChildren<{ css?: PicnicCss }>) {
  return (
    <Box
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        px: '$space6',
        py: '$space5',
        display: 'flex',
        justifyContent: 'flex-end',
        border: '1px solid $borderDefault',
        background: '$bgDefault',
        ...css,
      }}
    >
      {children}
    </Box>
  );
}

export { FixedFormFooter };
