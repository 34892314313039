import { useField, useFormikContext } from 'formik';
import React, { FC, useState, useMemo, useEffect } from 'react';

import { useCompanyFeatureFlag, API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import {
  Box,
  Icon,
  Text,
  Paginator,
  Checkbox,
  LoadingIndicator,
  Table as GridTable,
  SearchBar,
} from '@attentive/picnic';

import { getErrorMessage } from '../../api/utils';
import { IntegrationFeature } from '../IntegrationFeature';

const KLAVIYO_API_BASE = '/integrations/klaviyo';

const FEATURE_TYPE = 'FEATURE_SYNC_LIST_IDENTIFIERS';

type KlaviyoList = {
  id: string;
  name: string;
};

const TABLE_ITEMS_PER_PAGE = 8;

enum SORT_FIELD {
  LIST_NAME,
  ID,
}

async function fetchLists() {
  // GMRU: GET /integrations/klaviyo/lists
  const response = await API.get<KlaviyoList[]>(`${KLAVIYO_API_BASE}/lists`);
  if (response.body == null || response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  return response.body;
}

const dataSort = (sortByField: SORT_FIELD, ascending: boolean) => {
  if (sortByField === SORT_FIELD.LIST_NAME) {
    return (a: KlaviyoList, b: KlaviyoList) => {
      return ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    };
  }

  if (sortByField === SORT_FIELD.ID) {
    return (a: KlaviyoList, b: KlaviyoList) => {
      return ascending ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id);
    };
  }
};

const sortLists = (lists: KlaviyoList[], sortByField: SORT_FIELD, asc: boolean) => {
  const sortFn = dataSort(sortByField, asc);
  const sorted = [...lists].sort(sortFn);
  return sorted;
};

const filterLists = (lists: KlaviyoList[], input: string) => {
  return lists.slice().filter((list) => list.name.toLowerCase().includes(input.toLowerCase()));
};

export const SyncEmailListsFeature: FC<{ label?: string; subtext?: string }> = ({
  label,
  subtext,
}) => {
  const [, { value: fieldEnabledValue }] = useField(`${FEATURE_TYPE}.enabled`);
  const [{ value: optInExclusionsJSON }, , { setValue: setOptInExclusionsJSON }] = useField(
    `${FEATURE_TYPE}.excludedListsForSyncingOptIns`
  );
  const [{ value: optOutExclusionsJSON }, , { setValue: setOptOutExclusionsJSON }] = useField(
    `${FEATURE_TYPE}.excludedListsForSyncingOptOuts`
  );
  const enableKlaviyoOptInSync = useCompanyFeatureFlag('ENABLE_KLAVIYO_OPT_IN_SYNC');
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('FEATURE_EMAIL_OPT_OUT_SYNC.enabled', fieldEnabledValue);
    setFieldValue(
      'FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS.enabled',
      fieldEnabledValue && enableKlaviyoOptInSync
    );
    setFieldValue('FEATURE_SYNC_LIST_COUNT.enabled', fieldEnabledValue);
  }, [fieldEnabledValue, enableKlaviyoOptInSync, setFieldValue]);

  const optInExclusions = useMemo<string[]>(
    () => (optInExclusionsJSON ? JSON.parse(optInExclusionsJSON) : []),
    [optInExclusionsJSON]
  );
  const optOutExclusions = useMemo<string[]>(
    () => (optOutExclusionsJSON ? JSON.parse(optOutExclusionsJSON) : []),
    [optOutExclusionsJSON]
  );

  const [sortedByField, setSortedByField] = useState(SORT_FIELD.LIST_NAME);
  const [ascending, setAscending] = useState(false);
  const [allOptOutsChecked, setAllOptOutsChecked] = useState<boolean | 'indeterminate'>();
  const [allOptInsChecked, setAllOptInsChecked] = useState<boolean | 'indeterminate'>();
  const [pageOffset, setPageOffset] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [listsToShow, setListsToShow] = useState<KlaviyoList[]>([]);
  const pageStart = pageOffset * TABLE_ITEMS_PER_PAGE;
  const pageEnd = (pageOffset + 1) * TABLE_ITEMS_PER_PAGE;

  const { data: lists, isFetching } = useQuery(['lists'], fetchLists, {
    onSuccess: (data) => setListsToShow(data),
  });

  const updateSort = (sortByField: SORT_FIELD) => {
    const order = sortByField === sortedByField ? !ascending : ascending;
    setSortedByField(sortByField);
    setAscending(order);
  };

  const optOutIsChecked = (list: KlaviyoList) => {
    if (list == null) {
      return true;
    }

    return optOutExclusions.findIndex((optedOutListId) => optedOutListId === list.id) === -1;
  };

  const optInIsChecked = (list: KlaviyoList) => {
    if (list == null) {
      return true;
    }

    return optInExclusions.findIndex((optedInListId) => optedInListId === list.id) === -1;
  };

  const toggleOptOut = (checked: boolean | 'indeterminate', list: KlaviyoList) => {
    const updatedExclusions = optOutExclusions.slice();
    if (!checked) {
      updatedExclusions.push(list.id);
    } else {
      updatedExclusions.splice(
        updatedExclusions.findIndex((listId) => listId === list.id),
        1
      );
    }
    setOptOutExclusionsJSON(JSON.stringify(updatedExclusions));
  };

  const toggleAllOptOuts = (checked: boolean | 'indeterminate') => {
    const updatedExclusions: string[] = [];
    setAllOptOutsChecked(!!checked);
    if (!checked) {
      lists?.forEach((list) => {
        updatedExclusions.push(list.id);
      });
    }
    setOptOutExclusionsJSON(JSON.stringify(updatedExclusions));
  };

  const toggleOptIn = (checked: boolean | 'indeterminate', list: KlaviyoList) => {
    const updatedExclusions = optInExclusions.slice();
    if (!checked) {
      updatedExclusions.push(list.id);
    } else {
      updatedExclusions.splice(
        updatedExclusions.findIndex((listId) => listId === list.id),
        1
      );
    }
    setOptInExclusionsJSON(JSON.stringify(updatedExclusions));
  };

  const toggleAllOptIns = (checked: boolean | 'indeterminate') => {
    const updatedExclusions: string[] = [];
    setAllOptInsChecked(!!checked);
    if (!checked) {
      lists?.forEach((list) => {
        updatedExclusions.push(list.id);
      });
    }
    setOptInExclusionsJSON(JSON.stringify(updatedExclusions));
  };

  useEffect(() => {
    if (optOutExclusions.length === 0) {
      setAllOptOutsChecked(true);
    } else if (optOutExclusions.length === lists?.length) {
      setAllOptOutsChecked(false);
    } else {
      setAllOptOutsChecked('indeterminate');
    }
  }, [optOutExclusions, lists]);

  useEffect(() => {
    if (optInExclusions.length === 0) {
      setAllOptInsChecked(true);
    } else if (optInExclusions.length === lists?.length) {
      setAllOptInsChecked(false);
    } else {
      setAllOptInsChecked('indeterminate');
    }
  }, [optInExclusions, lists]);

  const sortedLists = useMemo<KlaviyoList[]>(
    () => sortLists(listsToShow, sortedByField, ascending),
    [listsToShow, sortedByField, ascending]
  );

  const handleSearchChange = (val: string) => {
    setSearchInput(val);
    setPageOffset(0);
  };

  const renderTableHeaders = () => {
    return (
      <GridTable.Header>
        <GridTable.HeaderRow>
          <GridTable.SortableHeaderCell
            onChange={() => updateSort(SORT_FIELD.LIST_NAME)}
            ascending={ascending}
            isSortActive={sortedByField === SORT_FIELD.LIST_NAME}
          >
            List Name
          </GridTable.SortableHeaderCell>
          <GridTable.HeaderCell>
            <Checkbox
              checked={allOptOutsChecked}
              onChange={toggleAllOptOuts}
              css={{ fontWeight: 'bold' }}
            >
              Opt-outs
            </Checkbox>
          </GridTable.HeaderCell>

          {enableKlaviyoOptInSync && (
            <GridTable.HeaderCell>
              <Checkbox
                checked={allOptInsChecked}
                onChange={toggleAllOptIns}
                css={{ fontWeight: 'bold' }}
              >
                Opt-ins
              </Checkbox>
            </GridTable.HeaderCell>
          )}
        </GridTable.HeaderRow>
      </GridTable.Header>
    );
  };

  const renderTableBody = (sortedAndFiltered: KlaviyoList[]) => {
    return (
      <GridTable.Body>
        {sortedAndFiltered.slice(pageStart, pageEnd).map((list) => (
          <GridTable.BodyRow key={list.id}>
            <GridTable.BodyCell>
              <Text>{list.name}</Text>
            </GridTable.BodyCell>
            <GridTable.BodyCell>
              <Checkbox
                checked={optOutIsChecked(list)}
                onChange={(checked) => {
                  toggleOptOut(checked, list);
                }}
              >
                {' '}
              </Checkbox>
            </GridTable.BodyCell>

            {enableKlaviyoOptInSync && (
              <GridTable.BodyCell>
                <Checkbox
                  checked={optInIsChecked(list)}
                  onChange={(checked) => {
                    toggleOptIn(checked, list);
                  }}
                >
                  {' '}
                </Checkbox>
              </GridTable.BodyCell>
            )}
          </GridTable.BodyRow>
        ))}
      </GridTable.Body>
    );
  };

  const renderTable = () => {
    if (isFetching) {
      return <LoadingIndicator />;
    }

    if (listsToShow.length < 1) {
      return (
        <Box
          css={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            mt: '$space4',
          }}
        >
          <Box>
            <Icon color="subdued" name="CircleExclamation" size="large" />
          </Box>
          There are no available lists to sync.
        </Box>
      );
    }

    const sortedAndFiltered = filterLists(sortedLists, searchInput);
    const listLength = sortedAndFiltered ? sortedAndFiltered.length : 0;
    const showPagination = listLength > TABLE_ITEMS_PER_PAGE;
    const columnSizes = enableKlaviyoOptInSync ? ['2fr', '1fr', '1fr'] : ['2fr', '1fr'];

    return (
      <>
        <GridTable columnSizes={columnSizes} css={{ mt: '$space4' }}>
          {renderTableHeaders()}
          {renderTableBody(sortedAndFiltered)}
        </GridTable>
        {showPagination && (
          <Box css={{ display: 'flex', flexDirection: 'row-reverse', pt: '$space3' }}>
            <Paginator
              totalItems={listLength}
              maxItemsPerPage={TABLE_ITEMS_PER_PAGE}
              onOffsetChange={setPageOffset}
              offset={pageOffset}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <IntegrationFeature
      type={FEATURE_TYPE}
      label={label ?? 'Sync email lists from Klaviyo'}
      subtext={
        subtext ??
        'Sync email lists from Klaviyo. Global opt-out list is synced automatically when feature is enabled.'
      }
      css={{ width: '100%' }}
    >
      <Box css={{ width: '100%' }}>
        <SearchBar
          onChange={handleSearchChange}
          value={searchInput}
          placeholder="Search lists"
          size="small"
        />
      </Box>
      {renderTable()}
    </IntegrationFeature>
  );
};
