import React, { forwardRef } from 'react';

import { IconButton, PicnicCss, styled } from '@attentive/picnic';

import { Logo } from '../Logo';

const BrandImagePreviewStyled = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$radius1',
  cursor: 'pointer',
});

interface BrandImagePreviewProps {
  src: string | null;
  altText: string;
  css?: PicnicCss;
  width: string;
  height: string;
  onRemove?: () => void;
  time?: Date | null;
  disabled?: boolean;
}

export const BrandImagePreview = forwardRef<HTMLDivElement, BrandImagePreviewProps>(
  ({ altText, src, onRemove, css, width, height, time, disabled }, ref) => {
    const imageUrl = time ? `${src}?${time}` : `${src}`;

    const brandImageCss = {
      borderRadius: '$radius1',
      backgroundColor: '$bgDefault',
      border: '$borderWidths$borderWidth1 solid $borderDefault',
      width,
      height,
    };

    return (
      <BrandImagePreviewStyled css={css} ref={ref}>
        <Logo src={imageUrl} altText={altText} css={brandImageCss} />
        {onRemove && (
          <IconButton
            iconName="Delete"
            variant="basic"
            description="Remove image"
            data-image-preview="remove-cover"
            css={{ ml: '$space2' }}
            onClick={onRemove}
            disabled={disabled}
          />
        )}
      </BrandImagePreviewStyled>
    );
  }
);
