import React, { ChangeEvent, FC, useState, useEffect } from 'react';

import { ToastType, useToast } from '@attentive/acore-utils';
import { Box, FileInput, FormField } from '@attentive/picnic';

import placeholderLogo from '../../assets/vendor_logos/placeholder.png';
import { validateFileUpload, MAX_IMG_GIF_FILE_SIZE } from '../../utils/mediaUploadUtils';
import { BrandImagePreview } from '../BrandImagePreview';
import { Logo } from '../Logo';

interface Props {
  imageUrl: string | null;
  suggestedWidth?: string;
  suggestedHeight?: string;
  width: string;
  height: string;
  errorText?: string;
  required?: boolean;
  onChange: (form: FormData | null) => void;
  disabled?: boolean;
}

export const ImageUpload: FC<Props> = ({
  imageUrl = '',
  errorText,
  required = false,
  width,
  height,
  suggestedWidth,
  suggestedHeight,
  onChange,
  disabled = false,
}) => {
  const [createToast] = useToast();
  const [lastUpdated, setLastUpdated] = useState<Date | null>(new Date());

  useEffect(() => {
    if (!imageUrl?.startsWith('blob')) {
      setLastUpdated(new Date());
    }
  }, [imageUrl]);

  const hasUploadedImage = !!imageUrl;

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = event.currentTarget;
      const form = validateFileUpload(files, MAX_IMG_GIF_FILE_SIZE);
      onChange(form);
      setLastUpdated(null);
    } catch (error) {
      createToast({
        type: ToastType.Error,
        title: 'File failed to upload',
        text: error.message,
      });
    }
  };

  return (
    <Box css={{ display: 'flex' }}>
      <FormField css={{ flex: 1, mr: '$space6' }}>
        <FormField.Label requirement={required ? 'required' : 'none'}>App icon</FormField.Label>
        {errorText && <FormField.ErrorText>{errorText}</FormField.ErrorText>}
        <Box css={{ display: 'flex' }}>
          {hasUploadedImage && (
            <BrandImagePreview
              css={{
                display: 'flex',
                boxSizing: 'border-box',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
              width={width}
              height={height}
              src={imageUrl}
              altText="image preview"
              onRemove={() => {
                onChange(null);
              }}
              time={lastUpdated}
              disabled={disabled}
            />
          )}
          {!hasUploadedImage && (
            <Box
              css={{
                display: 'flex',
                mr: '$space6',
                background: '#EFF0F0',
                height,
                width,
                border: '1px solid #545759',
                borderRadius: '4px',
                boxSizing: 'border-box',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              <Logo src={placeholderLogo} altText="image placeholder" />
            </Box>
          )}
          {!hasUploadedImage && (
            <Box css={{ display: 'flex', mb: '$space8', flexDirection: 'column' }}>
              <Box css={{ display: 'flex', mb: '$space8', flexDirection: 'row' }}>
                <FileInput
                  css={{
                    fontSize: '15px',
                    mr: '$space6',
                    background: '#FFFFFF',
                    height: '48px',
                    width: '170px',
                    border: '1px solid #545759',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  id="image-file-upload"
                  variant="secondary"
                  accept="image/png,image/jpeg,image"
                  onChange={(event) => handleFileUpload(event)}
                  disabled={disabled}
                >
                  Upload app icon
                </FileInput>
                <FormField.HelperText
                  css={{ mr: '$space6', padding: '12px 0px', fontSize: '15px' }}
                >
                  No file added
                </FormField.HelperText>
              </Box>
              <Box>
                <FormField.HelperText css={{ width: '292px', height: '60px' }}>
                  Upload a {suggestedWidth || width} by {suggestedHeight || height} image in PNG or
                  JPG file format. This icon appears throughout Attentive and represents your app.
                </FormField.HelperText>
              </Box>
            </Box>
          )}
        </Box>
      </FormField>
    </Box>
  );
};
