import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { AnyObjectSchema, object, string } from 'yup';

import { API } from '@attentive/acore-utils';
import { UseMutationResult } from '@attentive/data/react-query';
import { PicnicCss, Stack, styled } from '@attentive/picnic';

import { IntegrationFormError } from '../../api/integrations';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import {
  IntegrationDetailsResponse,
  IntegrationFormErrorResponse,
  IntegrationConfigurationRequest,
  IntegrationConnectionRequest,
  IntegrationSettingsRequest,
  IntegrationFormFeatureValues,
  IntegrationFormValues,
} from '../../types';

import { ErrorBanner } from './ErrorBanner';
import { LoadingScreen } from './LoadingScreen';

const StyledForm = styled(FormikForm, {});

interface FieldValidationConfig {
  [name: string]: { required: boolean };
}

interface ValidationConfig {
  fields?: FieldValidationConfig;
  features?: { [type: string]: FieldValidationConfig };
}

type FieldsPayload = Record<string, string>;
type FeaturesPayload = Record<string, { enabled: boolean; fields: FieldsPayload }>;
type Payload = { fields: FieldsPayload; features?: FeaturesPayload };

interface Props {
  loadingText: string;
  initialValues: IntegrationFormValues;
  formHasfields?: boolean;
  validationConfig?: ValidationConfig;
  validationSchema?: AnyObjectSchema;
  mutation: () => UseMutationResult<
    IntegrationDetailsResponse | IntegrationFormErrorResponse | void,
    unknown,
    {
      vendor: string;
      payload:
        | IntegrationConfigurationRequest
        | IntegrationConnectionRequest
        | IntegrationSettingsRequest;
    },
    unknown
  >;
  onComplete: () => void;
  css?: PicnicCss;
}

export const IntegrationForm: FC<PropsWithChildren<Props>> = ({
  loadingText,
  initialValues,
  validationConfig = {},
  validationSchema,
  formHasfields,
  mutation,
  onComplete,
  css,
  children,
}) => {
  if (formHasfields && isEmpty(initialValues)) {
    throw new Error(
      `👋 Attentive Engineer:
      "initialValues" cannot be empty. According to Formik, even
      if your form is empty by default, you must initialize all
      fields with initial values otherwise React will throw an
      error saying that you have changed an input from uncontrolled
      to controlled. This means for an empty form, you should provide
      an exhaustive list of fields set to empty strings.`.replace(/\s+/g, ' ')
    );
  }

  const { vendorKey, vendorDetails } = useIntegrationContext();

  const { mutateAsync, isError, isLoading, error } = mutation();
  const baseError =
    isError && ((error as IntegrationFormError).baseError || (error as Error).message);

  const handleSubmit = useCallback(
    async (
      values: IntegrationFormValues,
      { setSubmitting, setFieldError, setFieldTouched }: FormikHelpers<IntegrationFormValues>
    ) => {
      const trimmedFieldsPayload: FieldsPayload = {};
      const trimmedFeaturesPayload: FeaturesPayload = {};
      Object.entries(values).forEach(([fieldOrFeatureName, value]) => {
        if (typeof value === 'string') {
          trimmedFieldsPayload[fieldOrFeatureName] = (value as string).trim();
          return;
        }

        const featureValues = value as IntegrationFormFeatureValues;
        const featureFieldEntries = Object.entries(featureValues).filter(
          ([name, _]) => name !== 'enabled'
        );

        trimmedFeaturesPayload[fieldOrFeatureName] = {
          enabled: featureValues.enabled as boolean,
          fields: Object.fromEntries(
            featureFieldEntries.map(([featureFieldName, featureValue]) => [
              featureFieldName,
              (featureValue as string).trim(),
            ])
          ),
        };
      });

      const payload = {} as Payload;
      payload.fields = trimmedFieldsPayload;
      if (!isEmpty(trimmedFeaturesPayload)) {
        payload.features = trimmedFeaturesPayload;
      }

      if (
        payload.features &&
        payload.features.BRIDGE_IDENTIFIERS &&
        !payload.features.BRIDGE_IDENTIFIERS.enabled
      ) {
        // GMRU: POST /user-profile/segmentable-attributes/disable-all
        await API.post(
          `/user-profile/segmentable-attributes/disable-all?vendor=${vendorDetails?.externalVendorApiId}`
        );
      }

      await mutateAsync(
        { vendor: vendorKey, payload },
        {
          onError: (serverError, _variables, _context) => {
            const integrationFormError = serverError as IntegrationFormError;
            const fieldErrors = integrationFormError.fieldErrors || {};
            const featureFieldErrors = integrationFormError.featureFieldErrors || {};

            Object.entries(fieldErrors).forEach(([fieldName, errorMessage]) => {
              setFieldError(fieldName, errorMessage);
              setFieldTouched(fieldName, true);
            });

            Object.entries(featureFieldErrors).forEach(([featureType, fields]) => {
              Object.entries(fields).forEach(([fieldName, errorMessage]) => {
                setFieldError(`${featureType}.${fieldName}`, errorMessage);
                setFieldTouched(`${featureType}.${fieldName}`, true);
              });
            });
          },
        }
      );

      setSubmitting(false);
      onComplete();
    },
    [vendorKey, mutateAsync, onComplete, vendorDetails?.externalVendorApiId]
  );

  const fieldValidationEntries = Object.entries(validationConfig.fields || {})
    .filter(([_, config]) => config.required)
    .map(([fieldName, _]) => [fieldName, string().required('This field is required.')]);

  const featureValidationEntries = Object.entries(validationConfig.features || {}).map(
    ([featureType, featureFields]) => [
      featureType,
      object(
        Object.fromEntries(
          Object.entries(featureFields)
            .filter(([_, config]) => config.required)
            .map(([fieldName, _]) => [
              fieldName,
              string().when('enabled', {
                is: true,
                then: string().required('This field is required.'),
              }),
            ])
        )
      ),
    ]
  );

  const generatedValidationSchema = object(
    Object.fromEntries([...fieldValidationEntries, ...featureValidationEntries])
  ).concat(validationSchema || object());

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={generatedValidationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <StyledForm css={css}>
        {isLoading && <LoadingScreen text={loadingText} />}
        {!isLoading && (
          <Stack spacing="$space6">
            {baseError && <ErrorBanner text={baseError} />}

            {children}
          </Stack>
        )}
      </StyledForm>
    </Formik>
  );
};
