import React from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { Select } from '@attentive/picnic';

import { getErrorMessage } from '../../api/utils';
import { logError } from '../../utils/logger';

const KLAVIYO_API_BASE = '/integrations/klaviyo';

type KlaviyoList = {
  id: string;
  name: string;
};

async function fetchLists() {
  // GMRU: GET /integrations/klaviyo/lists
  const response = await API.get<KlaviyoList[]>(`${KLAVIYO_API_BASE}/lists`);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
}

function ListControl({
  value,
  state,
  onChange,
}: {
  value: string;
  state?: 'error' | 'normal';
  onChange: (value: string) => void;
}) {
  const { data = [] } = useQuery(['klaviyo-lists'], () => {
    return fetchLists().catch((e) => {
      logError(e);

      // If a value was previously saved, set that as an
      // option so that it shows up in the input even if
      // we fail to retrieve any audiences.
      if (value) {
        return [{ id: value, name: '' }];
      }
    });
  });

  return (
    <Select
      placeholder="Select list"
      state={state}
      value={value}
      onChange={(v: string) => onChange(v)}
    >
      {data.map((list) => (
        <Select.Item key={list.id} value={list.id}>{`${list.name} (${list.id})`}</Select.Item>
      ))}
    </Select>
  );
}

export { ListControl };
