import React from 'react';

import { Box, Icon } from '@attentive/picnic';

import { IntegrationFieldType } from '../../../types';
import { IntegrationField } from '../../IntegrationField';
import { AdditionalProperties } from '../../SettingsStandard/controls/AdditionalProperties';

function SyncAdditionalData({ isSuperUser }: { isSuperUser: boolean }) {
  return (
    <Box>
      <IntegrationField
        name="postWithCoupon"
        type={IntegrationFieldType.CHECKBOX}
        label="Coupon"
        required={false}
        css={{ width: '100%', mb: '$space4' }}
      />
      {isSuperUser && (
        <IntegrationField
          name="postWithPhone"
          type={IntegrationFieldType.CHECKBOX}
          label={
            <Box css={{ display: 'flex', alignItems: 'center' }}>
              Phone number
              <Icon name="ShieldPerson" size="medium" css={{ ml: '$space2' }} />
            </Box>
          }
          required={false}
          css={{ width: '100%', mb: '$space4' }}
        />
      )}
      <AdditionalProperties vendorName="Klaviyo" name="customFields" />
    </Box>
  );
}

export { SyncAdditionalData };
